export const OPEN_MAIN_STEPS_BLOCK = "OPEN_MAIN_STEPS_BLOCK";

export const SET_ACTIVE_PROPERTY_FROM_MAP = "SET_ACTIVE_PROPERTY_FROM_MAP";
export const SET_ACTIVE_PROPERTY = "SET_ACTIVE_PROPERTY";
export const SET_ADDITIONAL_ADDRESS = "SET_ADDITIONAL_ADDRESS";

export const SET_PROPERTY_DETAILS = "SET_PROPERTY_DETAILS";

export const SET_USER_DATA = "SET_USER_DATA";
export const SET_USER_PROFILE = "SET_USER_PROFILE";

export const CLEAR_STEPS_STATE = "CLEAR_STEPS_STATE";

export const SET_DETAILS = "SET_DETAILS";

export const SET_UTILITIES_DATA = "SET_UTILITIES_DATA";

export const CREATE_PERSONAL_ACCOUNT = "CREATE_PERSONAL_ACCOUNT";

export const CLEAR_SIMILAR_PROPERTIES_LIST = "CLEAR_SIMILAR_PROPERTIES_LIST";

export const SHOW_STEPS_ON_HEADER = "SHOW_STEPS_ON_HEADER";

export const MODIFY_PROPERTY = "MODIFY_PROPERTY";

export const GET_INFO_AGENCY_FROM_GOOGLE = "GET_INFO_AGENCY_FROM_GOOGLE";
export const GET_INFO_AGENCY_FROM_GOOGLE_SUCCESS =
	"GET_INFO_AGENCY_FROM_GOOGLE_SUCCESS";
export const GET_INFO_AGENCY_FROM_GOOGLE_ERROR =
	"GET_INFO_AGENCY_FROM_GOOGLE_ERROR";

export const CLEAR_AUTOCOMPLETE_ITEMS = "CLEAR_AUTOCOMPLETE_ITEMS";
export const GET_AUTOCOMPLETE_ITEMS = "GET_AUTOCOMPLETE_ITEMS";
export const GET_AUTOCOMPLETE_ITEMS_SUCCESS = "GET_AUTOCOMPLETE_ITEMS_SUCCESS";
export const GET_AUTOCOMPLETE_ITEMS_ERROR = "GET_AUTOCOMPLETE_ITEMS_ERROR";

export const GET_DATA_PROPERTIES_AGENCY = "GET_DATA_PROPERTIES_AGENCY";
export const GET_DATA_PROPERTIES_AGENCY_SUCCESS =
	"GET_DATA_PROPERTIES_AGENCY_SUCCESS";
export const GET_DATA_PROPERTIES_AGENCY_ERROR =
	"GET_DATA_PROPERTIES_AGENCY_ERROR";

export const GET_NEXT_PAGE_SIMILAR_PROPERTY = "GET_NEXT_PAGE_SIMILAR_PROPERTY";

export const UPDATE_ADDRESS_LIST = "UPDATE_ADDRESS_LIST";

export const PLEASE_VERIFY_EMAIL = "PLEASE_VERIFY_EMAIL";
export const CLOSE_VERIFY_EMAIL_MODAL = "CLOSE_VERIFY_EMAIL_MODAL";

export const CHECK_IF_EXIST_EMAIL = "CHECK_IF_EXIST_EMAIL";
export const CHECK_IF_EXIST_EMAIL_SUCCESS = "CHECK_IF_EXIST_EMAIL_SUCCESS";
export const CHECK_IF_EXIST_EMAIL_ERROR = "CHECK_IF_EXIST_EMAIL_ERROR";

export const VERIFY_EMAIL = "VERIFY_EMAIL";
export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS";
export const VERIFY_EMAIL_ERROR = "VERIFY_EMAIL_ERROR";

export const NEXT_STEP_REQUEST = "NEXT_STEP_REQUEST";
export const PREV_STEP_REQUEST = "PREV_STEP_REQUEST";

export const SEND_STEPS_DATA_REQUEST = "SEND_STEPS_DATA_REQUEST";
export const NO_ESTIMATION_PROPERTY = "NO_ESTIMATION_PROPERTY";

export const CREATE_PROPERTY_REQUEST = "CREATE_PROPERTY_REQUEST";
export const CREATE_PROPERTY_SUCCESS = "CREATE_PROPERTY_SUCCESS";
export const CREATE_PROPERTY_ERROR = "CREATE_PROPERTY_ERROR";

export const SET_SIMILAR_PROPERTY_PAGINATION_INFO =
	"SET_SIMILAR_PROPERTY_PAGINATION_INFO";
export const SET_SIMILAR_PROPERTY_LOCATIONS = "SET_SIMILAR_PROPERTY_LOCATIONS";
export const SET_SIMILAR_PROPERTY_BY_AGENCY = "SET_SIMILAR_PROPERTY_BY_AGENCY";

export const GET_SIMILAR_PROPERTY_SUCCESS = "GET_SIMILAR_PROPERTY_SUCCESS";
export const GET_SIMILAR_PROPERTY_ERROR = "GET_SIMILAR_PROPERTY_ERROR";

export const UPDATE_PROPERTY_REQUEST = "UPDATE_PROPERTY_REQUEST";
export const UPDATE_PROPERTY_SUCCESS = "UPDATE_PROPERTY_SUCCESS";
export const UPDATE_PROPERTY_ERROR = "UPDATE_PROPERTY_ERROR";

export const GET_USER_PROPERTY_REQUEST = "GET_USER_PROPERTY_REQUEST";
export const GET_USER_PROPERTY_SUCCESS = "GET_USER_PROPERTY_SUCCESS";
export const GET_USER_PROPERTY_ERROR = "GET_USER_PROPERTY_ERROR";

export const GET_PRICE_PROPERTY_REQUEST = "GET_PRICE_PROPERTY_REQUEST";
export const GET_PRICE_PROPERTY_SUCCESS = "GET_PRICE_PROPERTY_SUCCESS";
export const GET_PRICE_PROPERTY_ERROR = "GET_PRICE_PROPERTY_ERROR";

export const SIGNUP_USER_REQUEST = "SIGNUP_USER_REQUEST";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNUP_USER_ERROR = "SIGNUP_USER_ERROR";

export const LOGIN_USER_REQUEST = "LOGIN_USER_REQUEST";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";

export const LOGOUT_USER_REQUEST = "LOGOUT_USER_REQUEST";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";

export const CLOSE_REMIND_PASSWORD_MODAL = "CLOSE_REMIND_PASSWORD_MODAL";
export const CLOSE_CHANGE_PASSWORD_MODAL = "CLOSE_CHANGE_PASSWORD_MODAL";

export const REMIND_PASSWORD_REQUEST = "REMIND_PASSWORD_REQUEST";
export const REMIND_PASSWORD_SUCCESS = "REMIND_PASSWORD_SUCCESS";
export const REMIND_PASSWORD_ERROR = "REMIND_PASSWORD_ERROR";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";

export const SHOW_MODAL_CONTACT_AGENT = "SHOW_MODAL_CONTACT_AGENT";
export const CLOSE_MODAL_CONTACT_AGENT = "CLOSE_MODAL_CONTACT_AGENT";

export const CONTACT_AGENCY_REQUEST = "CONTACT_AGENCY_REQUEST";
export const CONTACT_AGENCY_SUCCESS = "CONTACT_AGENCY";
export const CONTACT_AGENCY_ERROR = "CONTACT_AGENCY_ERROR";

export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";

export const SET_MAIN_PROPERTY = "SET_MAIN_PROPERTY";
