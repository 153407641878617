import { combineReducers } from "redux";

import userInfo from "./userReducer";
import agency from "./agencyReducers";

const rootReducer = combineReducers({
	userInfo,
	agency,
});

export default rootReducer;
