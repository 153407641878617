import Link, { LinkProps } from "next/link";
import { useRouter, NextRouter } from "next/router";
import React from "react";
import { config } from "../config/siteConfigs";
import { UrlObject } from "url";

const links = JSON.parse(process.env.LINKS);

type Locale = string & ("en" | "fr" | "nl");

interface RouterLinkProps extends LinkProps {
	href: string;
	children: any;
	style?: React.CSSProperties;
	className?: string;
}

const useLinkTranslation = () => {
	const router = useRouter();
	// const { locale, asPath } = router as {
	//   locale: Locale;
	//   asPath: string;
	// };

	const routerPush = (
		link: string | UrlObject,
		as?: string | UrlObject,
		options?: any
	): Promise<Boolean> => {
		return router.push(
			translateLinkWithLocale(link as string, options?.locale ?? router.locale),
			as,
			{
				locale: router.locale,
				...options,
			}
		);
	};

	const translateLink = (link: string) => {
		if (link === "/home") return `${config.siteTitle}/${router.locale}`;
		return translateLinkWithLocale(link, router.locale as Locale);
	};

	const translateLinkForSearchLink = (link: string) => {
		return translateLinkWithLocaleForSearchLink(link, router.locale as Locale);
	};

	const translateCurrentPath = (locale: Locale) => {
		return translateLinkWithLocale(router.asPath, locale);
	};

	const translateLinkWithLocale = (link: string, locale: Locale) => {
		const firstIteration = link.split("?");
		const secondIteration = firstIteration[0]
			.split("/")
			.map((value) => {
				return (
					Object.keys(links).find((_value) => {
						return (
							links[_value]?.en === value ||
							links[_value]?.fr === value ||
							links[_value]?.nl === value
						);
					}) ?? value
				);
			})
			.map((value, index) => {
				if (index === 0) return value;
				if (links[value] && links[value][locale]) {
					return links[value][locale];
				} else {
					return value;
				}
			})
			.join("/");

		// return `${config.siteUrl}${
		return `${locale === "nl" ? "/nl" : `/${locale}`}${secondIteration}${
			firstIteration[1] ? `?${firstIteration[1]}` : ""
		}`;
	};

	const translateLinkWithLocaleForSearchLink = (
		link: string,
		locale: Locale
	) => {
		const firstIteration = link.split("?");
		const secondIteration = firstIteration[0]
			.split("/")
			.map((value) => {
				return (
					Object.keys(links).find((_value) => {
						return (
							links[_value]?.en === value ||
							links[_value]?.fr === value ||
							links[_value]?.nl === value
						);
					}) ?? value
				);
			})
			.map((value, index) => {
				if (index === 0) return value;
				if (links[value] && links[value][locale]) {
					return links[value][locale];
				} else {
					return value;
				}
			})
			.join("/");

		return `${config.siteUrl}${
			locale === "nl" ? "" : `/${locale}`
		}${secondIteration}${firstIteration[1] ? `?${firstIteration[1]}` : ""}`;
	};

	const RouterLink = ({ href, children, ...props }: RouterLinkProps) => {
		return (
			<Link
				{...props}
				href={translateLinkWithLocale(href, router.locale as Locale)}
				passHref
			>
				{children}
			</Link>
		);
	};

	return {
		translateCurrentPath,
		RouterLink,
		routerPush,
		translateLink,
		translateLinkForSearchLink,
		translateLinkWithLocaleForSearchLink,
		translateLinkWithLocale,
	};
};

export { useLinkTranslation };
