// Site Config =============================================================
export const config = {
	siteTitle: process.env.SITE_TITLE,
	apiDomain: process.env.APP_API,
	metricKey: process.env.GOOGLE_METRIC_KEY,
	cookieKey: process.env.COOKIE_KEY,
	siteUrl: process.env.SITE_URL,
	cityPagesEnabled: process.env.CITY_PAGES_ENABLED === "true" ? true : false,
	currentEstimationVersion: process.env.CURRENT_ESTIMATION_VERSION,
	currentDashboardVersion: process.env.CURRENT_DASHBOARD_VERSION,
	appEnv: process.env.APP_ENV,
};

// Google Maps Configs =====================================================
export const googleMapConfig = {
	apiKey: process.env.GOOGLE_MAP_API_KEY || "",
};

export const userToken =
	typeof window === "object" && localStorage.getItem("access_token");
